















































import Vue from 'vue';
import cssVars from 'css-vars-ponyfill';
import { ITherapyChoicePoll, ITherapyContentPoll } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-poll',
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentPoll,
      required: true,
    },
  },
  data() {
    return {
      showResults: true,
    };
  },
  computed: {
    selected(): ITherapyChoicePoll | null {
      return this.therapyContent.choices.find(c => c.selected) as ITherapyChoicePoll;
    },
  },
  mounted() {
    cssVars({});
    if (this.selected) {
      this.$emit('completeElement', { valid: true, feedback: [ this.selected.feedbackStyleContent ], oldSelection: true });
    }
  },
  methods: {
    selectChoice(choice: ITherapyChoicePoll) {
      this.therapyContent.choices.forEach(c => {
        c.selected = false;
      });
      choice.selected = true;
      this.$emit('completeElement', { valid: true, feedback: [ choice.feedbackStyleContent ] });
    },
    voteValue(choice: ITherapyChoicePoll): number | null {
      if (!this.selected) return null;
      const totalVotes = this.therapyContent.choices.reduce((acc, v) => acc + v.votesCount, 0) + 1;
      const choiceVotes = choice.votesCount + (choice.selected ? 1 : 0);
      const percent = Math.round((choiceVotes / totalVotes) * 100);
      this.$log.debug('Choice ', choiceVotes, ' Total ', totalVotes, ' Percent = ', percent, ' for choice ', choice.content);
      return percent;
    },
  },
});
