
















import Vue from 'vue';
import { INote } from '@/scripts/store/modules/tools/notes/types';
import InputNoteNative from '@/views/tools/notes/input-note-native.vue';
import InputNoteWeb from '@/views/tools/notes/input-note-web.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'InputNoteWrapperElement',
  components: { InputNoteNative, InputNoteWeb },
  props: {
    note: {
      type: Object as () => INote,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    safeNote(): INote {
      return this.note !== null ? this.note : {
        id: undefined,
        note: '',
        date: new Date(),
        type: 'GENERAL',
        uen: '',
        dictation: false,
      } as INote;
    },
    noteTitle(): string {
      return `${this.$t('app.tools.note.note')}: ${this.formatDate(this.safeNote.date)}`;
    },
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
    }),
  },
  methods: {
    onUpdated(noteUpdate: { note: string, dictation: boolean }) {
      const newNote: INote = {
        ...this.safeNote,
        note: noteUpdate.note,
        dictation: noteUpdate.dictation,
      };
      this.$emit('updated', newNote);
    },
    formatDate(date: Date) {
      return new Date(date || new Date().toString()).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
});
