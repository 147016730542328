var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-poll", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "gap-xs-2": "" } },
        _vm._l(_vm.therapyContent.choices, function(choice, index) {
          return _c(
            "v-flex",
            { key: index, attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: { "gap-xs-3": "" },
                  on: {
                    click: function($event) {
                      return _vm.selectChoice(choice)
                    }
                  }
                },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          class: { selected: choice.selected },
                          attrs: {
                            outline: !choice.selected,
                            round: "",
                            large: "",
                            icon: ""
                          }
                        },
                        [
                          choice.selected
                            ? _c("v-icon", [_vm._v("mdi-check")])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "pa-0": "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                "justify-center": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("span", {
                                  staticClass: "starling-body",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.markup(choice.content)
                                    )
                                  }
                                })
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                "justify-center": "",
                                "align-center": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { grow: "" } },
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "radius-10 ma-0",
                                    class: { selected: choice.selected },
                                    attrs: {
                                      color: choice.selected
                                        ? "primary"
                                        : "darkgrey",
                                      height: "18",
                                      value: _vm.voteValue(choice) || 0
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-center",
                                  attrs: { shrink: "", "px-3": "" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "font-weight-bold title":
                                          choice.selected &&
                                          _vm.$vuetify.breakpoint.smAndDown,
                                        "font-weight-bold headline":
                                          choice.selected &&
                                          _vm.$vuetify.breakpoint.mdAndUp
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.voteValue(choice) !== null
                                              ? _vm.voteValue(choice)
                                              : ""
                                          ) +
                                          "%\n                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }