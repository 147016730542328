var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-decision", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "text-left": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-list",
                { staticClass: "pa-0", attrs: { "three-line": "" } },
                _vm._l(_vm.therapyContent.choices, function(choice, index) {
                  return _c(
                    "v-list-tile",
                    {
                      key: index,
                      class: {
                        selected: choice.selected,
                        "mb-2": index < _vm.therapyContent.choices.length - 1
                      },
                      attrs: {
                        "justify-center": "",
                        "align-center": "",
                        choice: choice.id
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectChoice(choice)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-tile-content",
                        [
                          _c(
                            "v-container",
                            {
                              attrs: {
                                fluid: "",
                                "pa-0": "",
                                "fill-height": ""
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  class: {
                                    "ml-2": _vm.$vuetify.breakpoint.mdAndUp
                                  },
                                  attrs: {
                                    row: "",
                                    "justify-center": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs11: "", sm10: "" } },
                                    [
                                      _c("span", {
                                        staticClass: "starling-body",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.markup(choice.content)
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        large: "",
                                        xs1: "",
                                        sm2: "",
                                        "text-right": ""
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }