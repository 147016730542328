var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _vm.header
        ? _c("element-styled-content", {
            attrs: { content: _vm.header, uen: _vm.uen }
          })
        : _vm._e(),
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return _c(
                "v-card",
                {
                  ref: "choiceCard",
                  staticClass: "submenu-card",
                  class: {
                    "elevation-12": hover,
                    "elevation-2": !hover,
                    locked: _vm.choice.locked
                  },
                  attrs: { disabled: _vm.choice.locked },
                  on: { click: _vm.selectItem }
                },
                [
                  _vm.graphic
                    ? _c("element-part-graphic", {
                        staticClass: "submenu-card-graphic",
                        attrs: { part: _vm.graphic, isCardContent: true }
                      })
                    : _vm._e(),
                  _c(
                    "v-layout",
                    {
                      staticClass: "submenu-card-content",
                      style: _vm.choiceStyle,
                      attrs: { "fill-height": "", "align-center": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("element-styled-content", {
                            staticClass: "submenu-card-info",
                            attrs: {
                              content: _vm.content,
                              uen: _vm.uen,
                              isCardContent: true
                            },
                            on: { updateBackground: _vm.updateBackground }
                          })
                        ],
                        1
                      ),
                      _vm.showStatus
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "submenu-card-status",
                              attrs: { shrink: "", "mr-3": "" }
                            },
                            [
                              _vm.choice.locked
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.markup("##_padlock")
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm.choice.initial
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.markup("##_initial")
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm.choice.started
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.markup("##_started")
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm.choice.completed
                                ? _c("div", {
                                    staticStyle: {
                                      color: "var(--StarlingSuccess)"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.markup("##_completed")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }