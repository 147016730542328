













import Vue from 'vue';
import { INote } from '@/scripts/store/modules/tools/notes/types.ts';
import InputNote from '@/views/tools/notes/input-note.vue';

export default Vue.extend({
  name: 'InputNoteWrapperElement',
  components: { InputNote },
  props: {
    note: {
      type: Object as () => INote,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    safeNote(): INote {
      return this.note !== null ? this.note : { id: undefined, note: '', date: new Date(), type: 'GENERAL', uen: '' } as INote;
    },
    noteTitle(): string {
      return `${this.$t('app.tools.note.note')}: ${this.formatDate(this.safeNote.date)}`;
    },
  },
  methods: {
    onUpdated(updatedNote: string) {
      const newNote: INote = {
        ...this.safeNote,
        note: updatedNote,
      };
      this.$emit('updated', newNote);
    },
    formatDate(date: Date) {
      return new Date(date || new Date().toString()).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
});
